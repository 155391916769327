<template>
    <div>
        <div
            style="
                font-size: 34px;
                font-weight: bold;
                margin-bottom: 22px;
                color: red;
            "
        >
            <p>
                <span>Sử dụng bằng cách copy thẻ bên dưới</span>
            </p>
        </div>

        <el-row>
            <p style="width: 280px" class="body-small-semi-bold">
                Default / Hover / Press
            </p>
            <el-button type="info" size="small"> info </el-button>
            <el-button size="small">Default</el-button>
            <el-button type="primary" size="small">Primary</el-button>
            <el-button type="primary" size="small">
                <span class="icon-refresh"></span>
                Primary
            </el-button>
            <el-button type="danger" size="small">Danger</el-button>
            <el-button size="small">
                <span class="icon-refresh"></span>
                Default Icon-Left
            </el-button>
            <el-button size="mini">
                Default Icon-Right
                <span class="icon-refresh"></span>
            </el-button>
            <el-button size="mini" class="button--only-icon">
                <span class="icon-refresh"></span>
            </el-button>
            <el-button
                type="danger"
                size="mini"
                plain
                class="button--only-icon"
            >
                <span class="icon-refresh"></span>
            </el-button>
            <el-button type="info" size="small" class="button--only-icon">
                <span class="icon-refresh"></span>
            </el-button>
        </el-row>

        <el-row>
            <p style="width: 280px" class="body-small-semi-bold">Disabled</p>
            <el-button disabled type="info" size="small"> info </el-button>
            <el-button disabled size="small">Default</el-button>
            <el-button disabled type="primary" size="small">Primary</el-button>
            <el-button disabled type="primary" size="small">
                <span class="icon-refresh"></span>
                Primary
            </el-button>
            <el-button disabled type="danger" size="small">Danger</el-button>
            <el-button disabled size="small">
                <span class="icon-refresh"></span>
                Default Icon-Left
            </el-button>
            <el-button disabled size="mini">
                Default Icon-Right
                <span class="icon-refresh"></span>
            </el-button>
            <el-button disabled size="mini" class="button--only-icon">
                <span class="icon-refresh"></span>
            </el-button>
            <el-button
                disabled
                type="danger"
                size="mini"
                plain
                class="button--only-icon"
            >
                <span class="icon-refresh"></span>
            </el-button>
            <el-button
                disabled
                type="info"
                size="small"
                class="button--only-icon"
            >
                <span class="icon-refresh"></span>
            </el-button>
        </el-row>

        <el-row>
            <p style="width: 280px" class="body-small-semi-bold">Link</p>
            <div class="section-cta-link">
                <el-link :underline="false" type="primary">primary</el-link>
                <el-link :underline="false" type="danger">danger</el-link>
            </div>
        </el-row>
        <el-row>
            <p style="width: 280px" class="body-small-semi-bold">
                Link Disabled
            </p>
            <div class="section-cta-link">
                <el-link disabled :underline="false" type="primary"
                    >primary</el-link
                >
                <el-link disabled :underline="false" type="danger"
                    >danger</el-link
                >
            </div>
        </el-row>

        <el-row>
            <p style="width: 280px" class="body-small-semi-bold">Link Icon</p>
            <div class="section-cta-link">
                <el-link :underline="false" type="primary">
                    <span class="icon-refresh"></span>
                    primary
                </el-link>
                <el-link :underline="false" type="primary">
                    primary

                    <span class="icon-refresh"></span>
                </el-link>
                <el-link :underline="false" type="danger">
                    <span class="icon-refresh"></span>
                    danger
                </el-link>
                <el-link :underline="false" type="danger">
                    danger
                    <span class="icon-refresh"></span>
                </el-link>
            </div>
        </el-row>
        <el-row>
            <p style="width: 280px" class="body-small-semi-bold">
                Link Icon Disabled
            </p>
            <div class="section-cta-link">
                <el-link disabled :underline="false" type="primary">
                    <span class="icon-refresh"></span>
                    primary
                </el-link>
                <el-link disabled :underline="false" type="danger">
                    <span class="icon-refresh"></span>
                    danger
                </el-link>
                <el-link disabled :underline="false" type="danger">
                    <span class="icon-refresh"></span>
                    danger
                </el-link>
                <el-link disabled :underline="false" type="danger">
                    danger
                    <span class="icon-refresh"></span>
                </el-link>
            </div>
        </el-row>

        <el-row>
            <p style="width: 280px" class="body-small-semi-bold">
                Link underline Icon
            </p>
            <div class="section-cta-link">
                <el-link type="primary">
                    <span class="icon-refresh"></span>
                    primary
                </el-link>
                <el-link type="primary">
                    primary

                    <span class="icon-refresh"></span>
                </el-link>
                <el-link type="danger">
                    <span class="icon-refresh"></span>
                    danger
                </el-link>
                <el-link type="danger">
                    danger
                    <span class="icon-refresh"></span>
                </el-link>
            </div>
        </el-row>
    </div>
</template>

<script>
export default {
    name: "CTA",
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.el-row {
    margin: 24px 12px;
    display: flex;
    align-items: center;
}
</style>
<style lang="scss">
.is-title {
    font-weight: 600;
    font-size: 25px;
    color: #000000;
}
.section-cta {
    padding-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
    .block-info {
        padding-top: 10px;
    }
}
.section-cta-link {
    padding-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 25px;
    .block-info {
        padding-top: 10px;
    }
}

/* =========================== */
.el-button {
    border: none;
    border-radius: 6px;
    span {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}
.el-button--small {
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 90px;
}

.el-button--mini {
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 88px;
}

.el-button--default {
    background: var(--neutral-0);
    color: var(--primary-600);
    box-shadow: 0px 0px 0px 1px var(--primary-300) inset;

    span {
        /* Text */
        color: var(--primary-600);
        text-align: center;
        /* Body small/Semibold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    &:hover {
        color: var(--primary-700);
        background: var(--primary-50);
        box-shadow: 0px 0px 0px 1px var(--primary-300) inset;
    }
    &:active,
    &:focus {
        color: var(--primary-800);
        background: var(--primary-100);
        box-shadow: 0px 0px 0px 1px var(--primary-300) inset;
    }
}
.el-button--default.is-disabled {
    color: var(--primary-300);
    background: var(--primary-50);
    box-shadow: 0px 0px 0px 1px var(--primary-200) inset;

    span {
        color: var(--primary-300);
    }
}

/* info */
.el-button--info {
    background: var(--neutral-0);
    color: var(--neutral-600);
    box-shadow: 0px 0px 0px 1px var(--neutral-300) inset;

    span {
        /* Text */
        color: var(--neutral-600);
        text-align: center;
        /* Body small/Semibold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    &:hover {
        color: var(--neutral-700);
        background: var(--neutral-50);
        box-shadow: 0px 0px 0px 1px var(--neutral-300) inset;
    }
    &:active,
    &:focus {
        color: var(--neutral-800);
        background: var(--neutral-100);
        box-shadow: 0px 0px 0px 1px var(--neutral-300) inset;
    }
}
.el-button--info.is-disabled {
    color: var(--neutral-300);
    background: var(--neutral-50);
    box-shadow: 0px 0px 0px 1px var(--neutral-200) inset;
}

/* primary */
.el-button--primary {
    background: var(--primary-600);
    color: var(--neutral-0);
    box-shadow: none;

    span {
        /* Text */
        color: var(--neutral-0);
        text-align: center;
        /* Body small/Semibold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    &:hover {
        background: var(--primary-700);
    }
    &:active,
    &:focus-within {
        color: var(--neutral-0);
        background: var(--primary-800);
        box-shadow: none;
    }
}
.el-button--primary.is-disabled {
    background: var(--primary-200);
    border: none;
}

/* Danger */

.el-button--danger {
    background: var(--secondary-red-600);
    color: var(--neutral-0);

    span {
        /* Text */
        text-align: center;
        /* Body small/Semibold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    &:hover {
        background: var(--secondary-red-700);
    }
    &:active,
    &:focus {
        color: var(--neutral-0);
        background: var(--secondary-red-800);
    }
}
.el-button--danger.is-disabled {
    background: var(--secondary-red-200);
}

/* Danger plain*/
.el-button--danger.is-plain {
    color: var(--secondary-red-600) !important;
    background: var(--neutral-0);
    box-shadow: 0px 0px 0px 1px var(--secondary-red-300) inset;

    span {
        /* Text */
        text-align: center;
        /* Body small/Semibold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    &:hover {
        color: var(--secondary-red-700) !important;
        background: var(--secondary-red-50);
        box-shadow: 0px 0px 0px 1px var(--secondary-red-300) inset;
    }
    &:active,
    &:focus {
        color: var(--secondary-red-800);
        background: var(--secondary-red-100);
        box-shadow: 0px 0px 0px 1px var(--secondary-red-300) inset;
    }
}
.el-button--danger.is-plain.is-disabled {
    color: var(--secondary-red-300) !important;
    background: var(--secondary-red-50);
    box-shadow: 0px 0px 0px 1px var(--secondary-red-200) inset;
}

.button--only-icon {
    min-width: auto;
    padding: 8px;
    span {
        font-size: 16px;
    }
}

/* LINK */
.el-link.el-link--primary {
    color: var(--primary-600);
    &:hover {
        color: var(--primary-700);
    }
    &:active,
    &:focus {
        color: var(--primary-800);
    }
}
.el-link.el-link--danger {
    color: var(--secondary-red-600);
    &:hover {
        color: var(--secondary-red-700);
    }
    &:active,
    &:focus {
        color: var(--secondary-red-800);
    }
}
.el-link.el-link--primary.is-disabled,
.el-link.el-link--danger.is-disabled {
    color: var(--neutral-300);
}
/* LINK ICON */
.el-link {
    span {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}
/* LINK ICON underline */
.el-link.el-link--primary.is-underline:hover:after {
    bottom: -3px;
    border-bottom: 1.5px solid var(--primary-700);
}
.el-link.el-link--danger.is-underline:hover:after {
    bottom: -3px;
    border-bottom: 1.5px solid var(--secondary-red-700);
}
</style>
